import {useContext} from "react";
import {StoreContext} from "../stores/StoreLoader";
import ClickableLink from "../components/utilities/ClickableLink";
import FontAwesome from "../components/utilities/FontAwesome";

export default function ErrorPage(props: {
    statusCode?: number,
}): React.ReactElement {
    const {organizationStore} = useContext(StoreContext);

    let title, description;
    switch (props.statusCode) {
        case 500:
            title = "Whoops! Something went wrong."
            description = "Don't worry, we're on it. The error has been logged and we're working hard to fix it."
            break;
        case 400:
            title = "Sorry, but you've reached a page that does not exist.";
            break;
        case 404:
        default:
            title = "Sorry, but you've reached a page that does not exist.";
            description = "Most likely this happened because you tried to access a page from the old website. Welcome to\n" +
                "                    the new website! Follow any of the links above to navigate through the new website."
    }

    return <div id="sb-main-content" className="sb-error-container sb-content-width" style={{maxWidth: "600px", paddingTop: "100px"}}>
        <div className="sb-error-block">
            <div className="sb-error-message">
                <FontAwesome prefix={'fas'} name={'fa-exclamation-triangle'} className="text-danger" style={{fontSize: "3rem", marginBottom: "1rem"}} />
                <h3 className="text-danger" style={{marginBottom: "1rem"}}><b>{title}</b></h3>
                <p>
                    {description}
                </p>
                {organizationStore?.organization &&
                    <ClickableLink className={"btn"}
                                   style={{border: "1px solid black", color: "black"}}
                                   title="Go to the homepage"
                                   href={organizationStore.organization?.json_data?.settings?.appearance?.showWelcomePage ? "/?showHomepage=true" : "/"}
                >Go Home</ClickableLink>}
            </div>
        </div>
    </div>
}
